import {constructor} from '../../../../mixins/constructor';

export default {
    name: "sport_education",
    mixins: [constructor],
    data() {
        return {
            complexId: '',
            hoverdBuilding: '',
            selectedBuilding: '',
            hoveredBuildingElement: '',
            selectedBuildingElement: '',
            categoryList: '',
            coords: [
                '631,491,787,582,900,515,856,490,881,471,926,497,1036,433,1012,419,1012,403,981,388,969,367,951,359,942,365,893,333,837,367,837,382,809,397,818,405,770,434,756,429,734,442,720,435,667,464,665,473',
                '673,221,723,247,772,244,803,263,849,261,1011,170,1010,151,982,132,987,113,988,87,977,69,910,31,889,22,850,23,822,30,713,94,698,108,695,134,667,153,664,177,673,190',
                '52,472,211,562,288,520,296,524,423,449,405,438,404,429,389,409,367,389,345,381,328,381,195,462,208,474,197,482,170,465,265,409,244,395,242,377,232,356,213,349,182,365,166,382,169,391,143,414,140,428,125,435,113,430,78,450',
                '222,160,264,187,247,201,329,249,353,236,401,263,507,198,473,177,473,163,372,98,329,72,294,80,253,115,249,141',
                '49,141,59,150,75,141,78,131,103,139,314,15,282,4',
                '515,312,538,281,567,251,606,224,646,212,681,213,732,243,706,242,672,252,644,266,614,290,590,314,568,339',
                '644,390,662,365,689,337,722,314,768,294,804,291,877,333,855,332,816,340,792,358,761,377,729,411,714,430',
                '714,259,774,290,759,294,713,273',
                '627,400,712,445,877,349,879,397,857,412,853,393,844,396,843,419,832,427,829,389,765,427,767,464,714,496,628,452',
                '429,242,508,163,592,146,680,199,601,218,521,295',
                '509,329,625,394,582,430,470,367',
                '60,265,129,282,320,170,254,152',
                '300,125,364,145,558,33,491,14',
                '513,321,713,433,748,390,791,355,845,333,877,336,877,347,715,442,513,332',
                '251,288,282,312,285,329,331,304,332,291,326,270,310,255',
                '427,231,443,240,498,205,488,196,481,206,443,227',
                '596,215,666,173,715,171,760,192,800,233,824,274,747,319,713,270,655,223',
                '429,318,453,306,472,309,492,312,512,320,530,333,549,352,567,372,590,406,662,367,647,342,607,295,558,271,519,264', '152,317,415,469,467,440,470,477,416,507,155,357',
                '343,286,432,235,435,255,346,308',
                '234,305,418,413,399,412,385,436,196,331,216,308',
                '414,112,444,106,475,119,491,149,494,168,479,183,471,157,423,126,401,135,404,121',
                '348,166,356,151,375,135,397,134,412,148,430,166,435,183,441,205,422,214,401,215,384,216,362,205,346,191',
                '379,276,500,345,511,336,392,267',
                '382,245,602,118,613,124,388,251',
                '651,119,779,191,767,202,639,127',
                '64,215,541,340,550,346,773,217',
                '661,387,665,431,809,361,809,325,806,317,802,306',
                '304,321,429,396,496,352,372,276',
                '554,351,783,220,861,262,623,397',
                '661,119,782,190,857,145,728,71',
                '06,203,534,68,605,114,373,249',
                '370,478,488,465,489,494,371,508',
                '319,381,268,430,327,464,328,454,385,420,351,394',
                '489,493,487,450,439,438,436,406,489,415,565,416,617,403,614,436,568,448,566,496',
                '510,230,556,255,566,233,577,207,602,185,631,168,658,164,679,182,626,146,608,141,576,147,552,160,528,195',
                '711,249,775,288,749,312,730,344,667,311,683,277',
                '212,323,469,465,611,386,613,432,550,467,547,446,470,488,393,453,395,472,210,366',
                '788,103,683,46,575,106,662,155,655,161,571,112,572,101,682,38,790,98',
                '865,233,931,194,870,159,871,154,930,187,867,224',
                '405,290,323,245,213,311,340,383,352,377,354,385,340,390,214,319,322,254,398,295',
                '401,428,466,465,536,425,530,419,466,456,406,422',
                '417,334,499,285,555,332,524,352',
                '401,455,392,466,449,492,454,485',
                '735,150,817,102,829,177,792,195',
                '474,485,541,449,546,458,480,492',
                '582,239,642,205,644,252,586,281',
                '235,313,305,348,363,318,364,337,347,347,313,369,235,326',
                '356,360,390,378,541,377,598,346,600,384,536,417,416,417,354,394',
                '844,111,843,200,870,211,871,149',
                '667,124,718,91,768,94,690,138',
                '790,279,792,327,938,242,935,197,883,227,882,267,801,307,801,277',
                '441,256,502,286,498,300,438,270',
                '439,297,498,328,493,337,444,315'
            ],

            radioArr: [],
            coordsArr: []
        }
    },
    watch: {},
    created() {
    },
    mounted() {
    },
    computed: {},
    methods: {}
}



